import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import './index.css';  // Make sure this line is present
import App from './App';
import Login from './Login';
import CreateUser from './CreateUser';
import MyProfile from './MyProfile';
import Dashboard from './app/pages/dashboard';

// Configure axios to always send credentials
axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/create-user" element={<CreateUser />} />
        <Route path="/app" element={<App />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
