import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import '../styles/dashboard.css';

export default function Dashboard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingUser, setEditingUser] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);

  // Function to fetch users from the backend
  const fetchUsers = useCallback(async (force = false) => {
    const now = new Date().getTime();
    const cachedData = localStorage.getItem('dashboardUsers');
    const cachedTimestamp = localStorage.getItem('dashboardLastFetched');

    // If we have cached data and it's less than 5 minutes old (and not forcing refresh)
    if (!force && cachedData && cachedTimestamp) {
      const cacheAge = now - parseInt(cachedTimestamp);
      if (cacheAge < 5 * 60 * 1000) { // 5 minutes
        setUsers(JSON.parse(cachedData));
        setLastFetched(new Date(parseInt(cachedTimestamp)));
        setLoading(false);
        return;
      }
    }

    try {
    //   const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/users`,
        {}, {withCredentials: true}
      );

    //   console.log(response);
      if(response.status === 401){
        window.location.replace('/app');
      }
      if (response.data.error) {
        console.error('Error fetching users:', response.data.error);
        setLoading(false);
        return;
      }
      
      setUsers(response.data);
      setLastFetched(new Date());
      setLoading(false);

      // Cache the new data
      localStorage.setItem('dashboardUsers', JSON.stringify(response.data));
      localStorage.setItem('dashboardLastFetched', now.toString());
    } catch (error) {
      console.error('Error fetching users:', error);
      if(error.response.status === 401){
        window.location.replace('/app');
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleRefresh = () => {
    setLoading(true);
    fetchUsers(true); // Force refresh
  };

  const handleEdit = (user) => {
    setEditingUser({ ...user });
  };

  const handleSave = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/dashboard/users/${userId}`,
        editingUser,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      // Update both state and cache with the new data
      const updatedUsers = users.map(user => 
        user._id === userId ? response.data : user
      );
      setUsers(updatedUsers);
      localStorage.setItem('dashboardUsers', JSON.stringify(updatedUsers));
      
      setEditingUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleCancel = () => {
    setEditingUser(null);
  };

  const handleChange = (e, field) => {
    setEditingUser({
      ...editingUser,
      [field]: e.target.value
    });
  };

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h1 className="dashboard-title">Voice Recorder Users Dashboard</h1>
        <div className="header-controls">
          {lastFetched && (
            <span className="last-updated">
              Last updated: {lastFetched.toLocaleTimeString()}
            </span>
          )}
          <button
            onClick={handleRefresh}
            className="refresh-button"
            disabled={loading}
          >
            {loading ? 'Refreshing...' : 'Refresh'}
          </button>
        </div>
      </div>

      <div className="dashboard-table-container">
        <div className="table-wrapper">
          <table className="dashboard-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Company</th>
                <th className="center-align">Pro Status</th>
                <th className="center-align">Trial Status</th>
                <th className="center-align">Usage</th>
                <th className="center-align">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  {editingUser && editingUser._id === user._id ? (
                    <>
                      <td>
                        <input
                          className="edit-input"
                          value={editingUser.email}
                          onChange={(e) => handleChange(e, 'email')}
                        />
                      </td>
                      <td>
                        <div className="name-inputs">
                          <input
                            className="edit-input"
                            value={editingUser.firstName}
                            onChange={(e) => handleChange(e, 'firstName')}
                            placeholder="First Name"
                          />
                          <input
                            className="edit-input"
                            value={editingUser.lastName}
                            onChange={(e) => handleChange(e, 'lastName')}
                            placeholder="Last Name"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          className="edit-input"
                          value={editingUser.businessCompany}
                          onChange={(e) => handleChange(e, 'businessCompany')}
                        />
                      </td>
                      <td className="center-align">
                        <select
                          className="edit-select"
                          value={editingUser.isPro}
                          onChange={(e) => handleChange(e, 'isPro')}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </td>
                      <td className="center-align">
                        <select
                          className="edit-select"
                          value={editingUser.usedFreeTrial}
                          onChange={(e) => handleChange(e, 'usedFreeTrial')}
                        >
                          <option value={true}>Used</option>
                          <option value={false}>Not Used</option>
                        </select>
                      </td>
                      <td className="center-align">
                        <div className="usage-info">
                          <div>Tokens: {editingUser.inputTokensUsed + editingUser.outputTokensUsed}</div>
                          <div>Cost: ${editingUser.totalCost.toFixed(2)}</div>
                        </div>
                      </td>
                      <td className="center-align">
                        <div className="button-group">
                          <button
                            onClick={() => handleSave(user._id)}
                            className="save-button"
                          >
                            Save
                          </button>
                          <button
                            onClick={handleCancel}
                            className="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{user.email}</td>
                      <td>{`${user.firstName} ${user.lastName}`}</td>
                      <td>{user.businessCompany}</td>
                      <td className="center-align">
                        <span className={`status-badge ${user.isPro ? 'pro' : 'free'}`}>
                          {user.isPro ? 'Pro' : 'Free'}
                        </span>
                      </td>
                      <td className="center-align">
                        <div className="usage-info">
                          <div>{user.usedFreeTrial ? 'Used' : 'Not Used'}</div>
                          <div>Attempts: {user.freeTrialAttempts}</div>
                        </div>
                      </td>
                      <td className="center-align">
                        <div className="usage-info">
                          <div>Tokens: {user.inputTokensUsed + user.outputTokensUsed}</div>
                          <div>Cost: ${user.totalCost.toFixed(2)}</div>
                        </div>
                      </td>
                      <td className="center-align">
                        <button
                          onClick={() => handleEdit(user)}
                          className="edit-button"
                        >
                          Edit
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {users.length === 0 && (
          <div className="empty-message">
            No users found
          </div>
        )}
      </div>
    </div>
  );
} 