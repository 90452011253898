import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import './App.css';
import RemoveBackgroundNoise from './app/components/RemoveBackgroundNoise';
import CreateSocialContent from './app/components/createSocialContent';
import FetchRecordings from './app/components/fetchRecordings';
import HandleDelete from './app/components/handleDelete';
import handleNoteSave from './app/components/handleNoteSave';
import HandlePause from './app/components/handlePause';
import HandlePlay from './app/components/handlePlay';
import HandleSeek from './app/components/handleSeek';
import HandleTimeUpdate from './app/components/handleTimeUpdate';
import createSocialContentHandlers from './app/components/handleSocialContent';
import startRecording from './app/components/startRecording';
import saveRecording from './app/components/saveRecording';
import handleTitleSave from './app/components/handleTitleSave';
import stopRecording from './app/components/stopRecording';
import handleRecentSeek from './app/components/handleRecentSeek';
import transcribeAudio from './app/components/transcribeAudio';
import summarizeAudio from './app/components/summarizeAudio';
import useSocketSetup from './app/hooks/useSocketSetup';
import createTweet from './app/components/createTweet';
import createLinkedin from './app/components/createLinkedin';
import axios from 'axios';

function VoiceRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [recordings, setRecordings] = useState([]);
  const [editingTitle, setEditingTitle] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [maxDuration] = useState(7200); // Maximum recording duration in seconds (e.g., 5 minutes)
  const timerRef = useRef(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [playbackStates, setPlaybackStates] = useState({});
  const [recentRecordingPlayback, setRecentRecordingPlayback] = useState({
    isPlaying: false,
    currentTime: 0,
    duration: 0
  });
  const recentAudioRef = useRef(null);
  const [processing, setProcessing] = useState({});
  const [transcribing, setTranscribing] = useState({});
  const [summarizing, setSummarizing] = useState({});
  const [creatingContent, setCreatingContent] = useState({});
  const [socialContentPlayback, setSocialContentPlayback] = useState({});
  const [message, setMessage] = useState(null);
  const [activeAIMenu, setActiveAIMenu] = useState(null);
  const [activeSocialMenu, setActiveSocialMenu] = useState(null);
  const aiMenuRef = useRef(null);
  const socialMenuRef = useRef(null);
  const [tweetContent, setTweetContent] = useState({});
  const [linkedinContent, setLinkedinContent] = useState({});
  const [freeTrialUsed, setFreeTrialUsed] = useState(false);
  const [freeTrialAttempts, setFreeTrialAttempts] = useState(0);
  const MAX_FREE_TRIAL_ATTEMPTS = 3; // Set this to your desired number of free attempts
  const [profile, setProfile] = useState({ isPro: false }); // Add this state
  const navigate = useNavigate(); // Add this hook

  const showMessage = (text, type) => {
    setMessage({ text, type });
    setTimeout(() => setMessage(null), 3000); // Hide message after 3 seconds
  };

  const handleDelete = HandleDelete(setRecordings, showMessage);

  const { removeBackgroundNoise, processing: bgNoiseProcessing } = RemoveBackgroundNoise();
  const createSocialContent = CreateSocialContent();
  const fetchRecordings = FetchRecordings();
  const handlePause = HandlePause(setPlaybackStates);
  const handlePlay = HandlePlay(setCurrentAudio, setPlaybackStates);
  const handleSeek = HandleSeek(setPlaybackStates);
  const handleTimeUpdate = HandleTimeUpdate(setPlaybackStates);

  // Use the useSocketSetup hook
  const socket = useSocketSetup(setRecordings, setTranscribing, setProcessing, setSummarizing, setCreatingContent);

  // Create the social content handlers
  const { 
    handleSocialContentPlay, 
    handleSocialContentPause, 
    handleSocialContentTimeUpdate, 
    handleSocialContentSeek 
  } = createSocialContentHandlers(setSocialContentPlayback);

  useEffect(() => {
    const loadRecordings = async () => {
      try {
        const fetchedRecordings = await fetchRecordings();
        setRecordings(fetchedRecordings);
      } catch (error) {
        console.error('Error loading recordings:', error);
      }
    };

    loadRecordings();
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile`);
      setProfile(response.data);
      setFreeTrialUsed(response.data.usedFreeTrial);
      setFreeTrialAttempts(response.data.freeTrialAttempts || 0);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const updateFreeTrial = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/update-free-trial`);
      setFreeTrialAttempts(prev => prev + 1);
      if (freeTrialAttempts + 1 >= MAX_FREE_TRIAL_ATTEMPTS) {
        setFreeTrialUsed(true);
      }
    } catch (error) {
      console.error('Error updating free trial:', error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (aiMenuRef.current && !aiMenuRef.current.contains(event.target)) {
        setActiveAIMenu(null);
      }
      if (socialMenuRef.current && !socialMenuRef.current.contains(event.target)) {
        setActiveSocialMenu(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTitleEdit = (filename) => {
    setEditingTitle(filename);
  };

  const handleTitleSaveWrapper = (filename, newTitle) => {
    handleTitleSave(filename, newTitle, setRecordings);
    setEditingTitle(null);
  };

  const handleNoteSaveWrapper = async (filename, newNote) => {
    console.log("handleNoteSaveWrapper called with filename:", filename, "and newNote:", newNote);
    if (editingNote === filename) {
      try {
        await handleNoteSave(filename, newNote, setRecordings, setEditingNote);
        showMessage('Note saved successfully', 'success');
      } catch (error) {
        console.error('Error in handleNoteSaveWrapper:', error);
        showMessage(error.message || 'Failed to save note', 'error');
      }
    }
  };

  const handleNoteClick = (filename) => {
    setEditingNote(filename);
  };

  const handleTitleClick = (filename) => {
    setEditingTitle(filename);
  };

  const handleRecentPlay = () => {
    if (recentAudioRef.current) {
      recentAudioRef.current.play();
      setRecentRecordingPlayback(prev => ({ ...prev, isPlaying: true }));
    }
  };

  const handleRecentPause = () => {
    if (recentAudioRef.current) {
      recentAudioRef.current.pause();
      setRecentRecordingPlayback(prev => ({ ...prev, isPlaying: false }));
    }
  };

  const handleRecentTimeUpdate = () => {
    if (recentAudioRef.current && !isNaN(recentAudioRef.current.duration)) {
      setRecentRecordingPlayback(prev => ({
        ...prev,
        currentTime: recentAudioRef.current.currentTime,
        duration: recentAudioRef.current.duration
      }));
    }
  };

  const handleRecentSeekWrapper = (e) => {
    handleRecentSeek(e, recentAudioRef, setRecentRecordingPlayback);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  useEffect(() => {
    if (audioRef.current) {
      if (currentAudio && playbackStates[currentAudio]?.isPlaying) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
          }).catch(error => {
            // Auto-play was prevented
            console.log("Playback prevented. Resetting state:", error);
            setPlaybackStates(prev => ({
              ...prev,
              [currentAudio]: { ...prev[currentAudio], isPlaying: false }
            }));
          });
        }
      } else {
        audioRef.current.pause();
      }
    }
  }, [playbackStates, currentAudio]);

  const handleStartRecording = () => {
    startRecording(
      setIsRecording,
      setRecordingDuration,
      timerRef,
      mediaRecorderRef,
      audioChunksRef,
      maxDuration
    );
  };

  const handleStopRecording = () => {
    stopRecording(
      setIsRecording,
      setAudioURL,
      mediaRecorderRef,
      audioChunksRef,
      timerRef
    );
  };

  const handleSaveRecording = async () => {
    await saveRecording(audioURL, setRecordings, showMessage);
    // Clear the audioURL after successful save to hide the playback section
    setAudioURL('');
    // Reset recent recording playback state
    setRecentRecordingPlayback({
      isPlaying: false,
      currentTime: 0,
      duration: 0
    });
  };

  const toggleAIMenu = (filename, event) => {
    event.stopPropagation();
    setActiveAIMenu(prev => prev === filename ? null : filename);
    setActiveSocialMenu(null); // Close the other menu
  };

  const toggleSocialMenu = (filename, event) => {
    event.stopPropagation();
    setActiveSocialMenu(prev => prev === filename ? null : filename);
    setActiveAIMenu(null); // Close the other menu
  };

  const handleAIOption = async (filename, option, event) => {
    event.stopPropagation();
    if (freeTrialUsed && !profile.isPro) {
      showMessage('Free trial used. Please subscribe under "My Profile" to continue using this feature.', 'error');
      return;
    }
    
    switch(option) {
      case 'transcribe':
        transcribeAudio(filename, setTranscribing);
        break;
      case 'summarize':
        summarizeAudio(filename, setSummarizing);
        break;
      default:
        console.log(`AI Option ${option} not implemented yet`);
    }
    
    if (!profile.isPro) {
      await updateFreeTrial();
    }
    setActiveAIMenu(null);
  };

  const handleSocialOption = async (filename, option, event) => {
    event.stopPropagation();
    if (freeTrialUsed && !profile.isPro) {
      showMessage('Free trial used. Please subscribe under "My Profile" to continue using this feature.', 'error');
      return;
    }
    
    switch(option) {
      case 'createSocialContent':
        createSocialContent(filename, setCreatingContent);
        break;
      case 'createTweet':
        if (recordings.find(r => r.filename === filename).transcription) {
          createTweet(filename, setTweetContent);
        } else {
          showMessage('Transcription is required to create a tweet', 'error');
        }
        break;
      case 'createLinkedin':
        if (recordings.find(r => r.filename === filename).transcription) {
          createLinkedin(filename, setLinkedinContent);
        } else {
          showMessage('Transcription is required to create a LinkedIn post', 'error');
        }
        break;
      default:
        console.log(`Social option ${option} not implemented yet`);
    }
    
    if (!profile.isPro) {
      await updateFreeTrial();
    }
    setActiveSocialMenu(null);
  };

  return (
    <div className="voice-recorder-container">
      {freeTrialUsed && !profile.isPro && (
        <div className="free-trial-message">
          <p>You have used up your free trial. Please subscribe to continue using AI and social features.</p>
          <button onClick={() => navigate('/my-profile')} className="subscribe-now-button">
            <i className="fas fa-star"></i> Subscribe Now
          </button>
        </div>
      )}
      <section className="recording-section">
        <button 
          className={`record-button ${isRecording ? "recording" : ""}`}
          onClick={isRecording ? handleStopRecording : handleStartRecording}
          >
          <i className={`fas ${isRecording ? "fa-stop" : "fa-microphone"}`}></i>
          <span className="button-text">
            {isRecording ? "Stop Recording" : "Start Recording"}
          </span>
        </button>
          {message && (
            <div className={`message-popup ${message.type}`}>
              {message.text}
            </div>
          )}
        {isRecording && (
          <div className="progress-bar-container">
            <div 
              className="progress-bar" 
              style={{width: `${(recordingDuration / maxDuration) * 100}%`}}
            ></div>
            <span className="progress-time">{formatTime(recordingDuration)}</span>
          </div>
        )}
      </section>
      {audioURL && (
        <section className="playback-section">
          <h3>Recent Recording</h3>
          <div className="custom-audio-player">
            <button 
              className={`play-pause-btn ${recentRecordingPlayback.isPlaying ? 'playing' : ''}`}
              onClick={recentRecordingPlayback.isPlaying ? handleRecentPause : handleRecentPlay}
            >
              <i className={`fas ${recentRecordingPlayback.isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
            </button>
            <div className="progress-bar" onClick={handleRecentSeekWrapper}>
              <div 
                className="progress" 
                style={{ width: `${(recentRecordingPlayback.currentTime / recentRecordingPlayback.duration) * 100 || 0}%` }}
              ></div>
            </div>
            <span className="time">
              {formatTime(recentRecordingPlayback.currentTime)} / {formatTime(recentRecordingPlayback.duration)}
            </span>
          </div>
          <audio
            ref={recentAudioRef}
            src={audioURL}
            preload="auto"
            onTimeUpdate={handleRecentTimeUpdate}
            onEnded={() => setRecentRecordingPlayback(prev => ({ ...prev, isPlaying: false }))}
          />
          <button className="save-button" onClick={handleSaveRecording}>
            <i className="fas fa-save"></i> Save Recording
          </button>
        </section>
      )}
      <section className="recordings-grid">
        <h2>Your Recordings</h2>
        <div className="grid-container">
          {recordings.map((recording, index) => (
            <div key={recording.filename} className="grid-item">
              {editingTitle === recording.filename ? (
                <div className="title-edit">
                  <input
                    type="text"
                    defaultValue={recording.title}
                    onBlur={(e) => handleTitleSaveWrapper(recording.filename, e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleTitleSaveWrapper(recording.filename, e.target.value);
                      }
                    }}
                    autoFocus
                    className="title-input"
                  />
                </div>
              ) : (
                <div className="title-section">
                  <h3 
                    onClick={() => handleTitleClick(recording.filename)}
                    className="editable-title"
                  >
                    {recording.title}
                  </h3>
                </div>
              )}
              <div className="audio-container">
                <div className="custom-audio-player">
                  <button 
                    className={`play-pause-btn ${playbackStates[recording.fileUrl]?.isPlaying ? 'playing' : ''}`}
                    onClick={() => playbackStates[recording.fileUrl]?.isPlaying 
                      ? handlePause(recording.fileUrl) 
                      : handlePlay(recording.fileUrl)}
                  >
                    <i className={`fas ${playbackStates[recording.fileUrl]?.isPlaying ? 'fa-pause' : 'fa-play'}`}></i>                 </button>
                  <div className="progress-bar" onClick={(e) => handleSeek(e, recording.fileUrl)}>
                    <div className="progress" style={{ width: `${(playbackStates[recording.fileUrl]?.currentTime / playbackStates[recording.fileUrl]?.duration) * 100 || 0}%` }}></div>
                  </div>
                  <span className="time">
                    {formatTime(playbackStates[recording.fileUrl]?.currentTime || 0)} / 
                    {formatTime(playbackStates[recording.fileUrl]?.duration || 0)}
                  </span>
                </div>
              </div>
              {editingNote === recording.filename ? (
                <div className="note-edit">
                  <textarea
                    defaultValue={recording.note || ''}
                    onBlur={(e) => handleNoteSaveWrapper(recording.filename, e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleNoteSaveWrapper(recording.filename, e.target.value);
                      }
                    }}
                    autoFocus
                    className="note-input"
                  />
                </div>
              ) : (
                <p 
                  className="note-display editable-note"
                  onClick={() => setEditingNote(recording.filename)}
                >
                  {recording.note || "Click to add a note"}
                </p>
              )}
              <div className="button-group">
                <button 
                  className="delete-button stylish-button" 
                  onClick={() => handleDelete(recording.filename)}
                >
                  <i className="fas fa-trash"></i> Delete
                </button>
                <button 
                  className="noise-removal-button stylish-button"
                  onClick={() => removeBackgroundNoise(recording.filename)}
                  disabled={processing[recording.filename]}
                >
                  <i className="fas fa-magic"></i> {processing[recording.filename] ? 'Processing...' : 'Remove Noise'}
                </button>
                <div className="options-dropdown">
                  <button 
                    className="ai-options-button stylish-button"
                    onClick={(e) => toggleAIMenu(recording.filename, e)}
                  >
                    <i className="fas fa-robot"></i> AI Options
                  </button>
                  {activeAIMenu === recording.filename && (
                    <div ref={aiMenuRef} className="options-menu">
                      <button 
                        onClick={(e) => handleAIOption(recording.filename, 'transcribe', e)}
                        disabled={transcribing[recording.filename]}
                      >
                        <i className="fas fa-file-alt"></i> {transcribing[recording.filename] ? 'Transcribing...' : 'Transcribe'}
                      </button>
                      <button 
                        onClick={(e) => handleAIOption(recording.filename, 'summarize', e)}
                        disabled={summarizing[recording.filename]}
                      >
                        <i className="fas fa-file-alt"></i> {summarizing[recording.filename] ? 'Summarizing...' : 'Summarize'}
                      </button>
                    </div>
                  )}
                </div>
                <div className="options-dropdown">
                  <button 
                    className="social-options-button stylish-button"
                    onClick={(e) => toggleSocialMenu(recording.filename, e)}
                  >
                    <i className="fas fa-share-alt"></i> Social Options
                  </button>
                  {activeSocialMenu === recording.filename && (
                    <div ref={socialMenuRef} className="options-menu">
                      <button 
                        onClick={(e) => handleSocialOption(recording.filename, 'createSocialContent', e)}
                        disabled={creatingContent[recording.filename]}
                      >
                        <i className="fas fa-share-alt"></i> {creatingContent[recording.filename] ? 'Creating...' : 'Create Social Content'}
                      </button>
                      <button 
                        onClick={(e) => handleSocialOption(recording.filename, 'createTweet', e)}
                        disabled={!recording.transcription}
                      >
                        <i className="fab fa-twitter"></i> Create Tweet
                      </button>
                      <button 
                        onClick={(e) => handleSocialOption(recording.filename, 'createLinkedin', e)}
                        disabled={!recording.transcription}
                      >
                        <i className="fab fa-linkedin"></i> Create Linkedin Post
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {recording.transcription && (
                <div className="transcription">
                  {/* <h4>Transcription:</h4> */}
                  {/* <p>{recording.transcription}</p> */}
                </div>
              )}
              {recording.summary && recording.summary.summary_of_summary && (
                <div className="summary">
                  <h4>Summary:</h4>
                  <p>{recording.summary.summary_of_summary}</p>
                </div>
              )}
              {recording.socialContent && (
                <div className="social-content">
                  <h4>Social Content:</h4>
                  <div className="custom-audio-player">
                    <button 
                      className={`play-pause-btn ${socialContentPlayback[recording.socialContent]?.isPlaying ? 'playing' : ''}`}
                      onClick={() => socialContentPlayback[recording.socialContent]?.isPlaying 
                        ? handleSocialContentPause(recording.socialContent) 
                        : handleSocialContentPlay(recording.socialContent)}
                    >
                      <i className={`fas ${socialContentPlayback[recording.socialContent]?.isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                    </button>
                    <div className="progress-bar" onClick={(e) => handleSocialContentSeek(e, recording.socialContent)}>
                      <div 
                        className="progress" 
                        style={{ width: `${(socialContentPlayback[recording.socialContent]?.currentTime / socialContentPlayback[recording.socialContent]?.duration) * 100 || 0}%` }}
                      ></div>
                    </div>
                    <span className="time">
                      {formatTime(socialContentPlayback[recording.socialContent]?.currentTime || 0)} / 
                      {formatTime(socialContentPlayback[recording.socialContent]?.duration || 0)}
                    </span>
                    <audio
                      id={`social-audio-${recording.socialContent}`}
                      src={recording.socialContent}
                      onTimeUpdate={() => handleSocialContentTimeUpdate(recording.socialContent)}
                      onEnded={() => handleSocialContentPause(recording.socialContent)}
                    />
                  </div>
                </div>
              )}
              {tweetContent[recording.filename] && (
                <div className="tweet-content">
                  <h4>Generated Tweet:</h4>
                  <p>{tweetContent[recording.filename]}</p>
                </div>
              )}
                     {linkedinContent[recording.filename] && (
                <div className="tweet-content">
                  <h4>Generated Linkedin Post:</h4>
                  <p>{linkedinContent[recording.filename]}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <audio
        ref={audioRef}
        src={currentAudio}
        id={`audio-${currentAudio}`} // Make sure this ID matches what's used in handleSeek.js
        onTimeUpdate={() => {
          if (currentAudio) handleTimeUpdate(currentAudio);
        }}
        onEnded={() => {
          if (currentAudio) handlePause(currentAudio);
        }}
      />

    </div>
  );
}

export default VoiceRecorder;